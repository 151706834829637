import { TypographyProps } from '@/atoms/00-ui/Typography/index.types';
import { cn } from '@/helpers/tailwind';

export const Typography = ({ variant, component = 'span', className, style, children, ...rest }: TypographyProps) => {
  const Component = component;

  return (
    <Component
      className={cn(``, {
        ['']: !variant,
        ['text-white text-3xl mobile:text-5xl leading-[42px] mobile:leading-[62px] tracking-[-0.9px]']:
          variant === 'h1',
        ['text-white text-2xl mobile:text-4xl leading-3xl mobile:leading-[44px]']: variant === 'h2',
        ['text-white text-[22px] mobile:text-[28px] leading-3xl mobile:leading-[39px]']: variant === 'h3',
        ['text-base leading-2x text-green-7 uppercase font-mono font-medium tracking-[1.92px]']: variant === 'preTitle',
        ['text-white text-base mobile:text-xl leading-2xl mobile:leading-[32px]']: variant === 'body1',
        ['text-white text-sm mobile:text-base leading-xl mobile:leading-[26px]']: variant === 'body2',
        ['text-white text-sm mobile:text-base leading-xl mobile:leading-[26px] font-medium']: variant === 'button',
        [`${className}`]: className,
      })}
      style={style}
      {...rest}
    >
      {children}
    </Component>
  );
};

import { cn } from '@/helpers/tailwind';
import { IArrowProps } from '@/atoms/00-ui/Arrow/index.types';

export const Arrow = ({ themeColor, className }: IArrowProps) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('min-w-[18px]', {
        [`${className}`]: className,
      })}
    >
      <path
        d="M6.51285 2.99494L12.2561 2.99477L16.0851 9.62716L10.341 9.62686L6.51285 2.99494Z"
        className={cn('transition ease-out duration-700', {
          ['fill-[#47546780] group-hover:fill-purple-7']: themeColor === 'gray',
          ['fill-[#f9fafb80] group-hover:fill-[#F9FAFB]']: themeColor === 'white',
        })}
      />
      <path
        d="M6.51213 16.2587L12.2562 16.259L16.085 9.6272L10.341 9.6269L6.51213 16.2587Z"
        className={cn('transition ease-out duration-700', {
          ['fill-[#0c111d80] group-hover:fill-purple-9']: themeColor === 'gray',
          ['fill-[#ffffff80] group-hover:fill-[#F9FAFB]']: themeColor === 'white',
        })}
      />
    </svg>
  );
};

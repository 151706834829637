import { cn } from '@/helpers/tailwind';
import { Arrow } from '@/atoms/00-ui/Arrow';
import { Typography } from '@/atoms/00-ui/Typography';
import { IProgressButtonProps } from '@/molecules/00-ui/ProgressButton/index.types';

export const ProgressButton = ({
  className,
  text,
  onClick,
  disabled,
  themeColor = 'black',
  testId,
  ...rest
}: IProgressButtonProps) => {
  return (
    <button
      data-testid={testId}
      className={cn(
        'relative group px-[20px] py-[11px] overflow-hidden rounded-full border-[1px] border-white/20',
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      <span className="relative z-10 flex gap-x-[8px] items-center">
        <Typography
          component="span"
          variant="button"
        >
          {text}
        </Typography>
        <Arrow themeColor="white" />
      </span>

      <span
        className={cn(
          `w-full h-full absolute inset-0 
          before:content-[''] before:block before:absolute before:inset-0 before:w-full before:h-full
          before:translate-x-[-100%] before:group-hover:translate-x-0 before:duration-[900ms] before:ease-linear`,
          {
            [`before:bg-linear-dark-blue bg-blue-4/70`]: themeColor === 'black',
            [`before:bg-linear-green-2 bg-linear-green-1`]: themeColor === 'green',
          },
        )}
      />
    </button>
  );
};
